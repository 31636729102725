/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ActiveWorkLog } from '../models/active-work-log';
import { activeWorklogDelete } from '../fn/active-work-log/active-worklog-delete';
import { ActiveWorklogDelete$Params } from '../fn/active-work-log/active-worklog-delete';
import { activeWorklogPost } from '../fn/active-work-log/active-worklog-post';
import { ActiveWorklogPost$Params } from '../fn/active-work-log/active-worklog-post';
import { activeWorklogPut } from '../fn/active-work-log/active-worklog-put';
import { ActiveWorklogPut$Params } from '../fn/active-work-log/active-worklog-put';
import { activeWorklogStopPost } from '../fn/active-work-log/active-worklog-stop-post';
import { ActiveWorklogStopPost$Params } from '../fn/active-work-log/active-worklog-stop-post';
import { WorkLog } from '../models/work-log';

@Injectable({ providedIn: 'root' })
export class ActiveWorkLogService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `activeWorklogPut()` */
  static readonly ActiveWorklogPutPath = '/active-worklog';

  /**
   * Update Active Worklog.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activeWorklogPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activeWorklogPut$Response(params: ActiveWorklogPut$Params, context?: HttpContext): Observable<StrictHttpResponse<WorkLog>> {
    return activeWorklogPut(this.http, this.rootUrl, params, context);
  }

  /**
   * Update Active Worklog.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activeWorklogPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activeWorklogPut(params: ActiveWorklogPut$Params, context?: HttpContext): Observable<WorkLog> {
    return this.activeWorklogPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkLog>): WorkLog => r.body)
    );
  }

  /** Path part for operation `activeWorklogPost()` */
  static readonly ActiveWorklogPostPath = '/active-worklog';

  /**
   * Create an active work log.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activeWorklogPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activeWorklogPost$Response(params: ActiveWorklogPost$Params, context?: HttpContext): Observable<StrictHttpResponse<ActiveWorkLog>> {
    return activeWorklogPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Create an active work log.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activeWorklogPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activeWorklogPost(params: ActiveWorklogPost$Params, context?: HttpContext): Observable<ActiveWorkLog> {
    return this.activeWorklogPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActiveWorkLog>): ActiveWorkLog => r.body)
    );
  }

  /** Path part for operation `activeWorklogDelete()` */
  static readonly ActiveWorklogDeletePath = '/active-worklog';

  /**
   * Delete the Active Work Log.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activeWorklogDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  activeWorklogDelete$Response(params?: ActiveWorklogDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return activeWorklogDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete the Active Work Log.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activeWorklogDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  activeWorklogDelete(params?: ActiveWorklogDelete$Params, context?: HttpContext): Observable<void> {
    return this.activeWorklogDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `activeWorklogStopPost()` */
  static readonly ActiveWorklogStopPostPath = '/active-worklog/stop';

  /**
   * Stop the active work log.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activeWorklogStopPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  activeWorklogStopPost$Response(params?: ActiveWorklogStopPost$Params, context?: HttpContext): Observable<StrictHttpResponse<WorkLog>> {
    return activeWorklogStopPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Stop the active work log.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activeWorklogStopPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  activeWorklogStopPost(params?: ActiveWorklogStopPost$Params, context?: HttpContext): Observable<WorkLog> {
    return this.activeWorklogStopPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkLog>): WorkLog => r.body)
    );
  }

}
