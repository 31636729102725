import {Component, OnDestroy, OnInit} from '@angular/core';
import {NbMenuItem, NbMenuService, NbSidebarService, NbThemeService} from '@nebular/theme';

import {Subscription} from 'rxjs';
import {AuthService, User} from '@auth0/auth0-angular';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  readonly USER_MENU_TAG: string = 'user-menu';
  readonly LOGOUT_MENU_ITEM: string = 'Logout';

  readonly USER_MENU: NbMenuItem[] = [
    {
      icon: 'log-out-outline',
      title: this.LOGOUT_MENU_ITEM,
    },
  ];

  private userSubscription: Subscription;
  user: User;

  darkMode: boolean = true;

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private authService: AuthService,
              private nbMenuService: NbMenuService) {
  }

  ngOnInit() {
    this.onDarkModeToggled();
    this.userSubscription = this.authService.user$.subscribe(user => this.user = user);
    this.nbMenuService.onItemClick().pipe(
      filter(({ tag }) => tag === this.USER_MENU_TAG))
      .subscribe(item => {
        if (item.item.title === this.LOGOUT_MENU_ITEM) {
          this.authService.logout();
        }
      });
  }

  ngOnDestroy() {
    this.userSubscription?.unsubscribe();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  onDarkModeToggled() {
    this.darkMode = !this.darkMode;
    if (this.darkMode) {
      this.themeService.changeTheme('dark');
    } else {
      this.themeService.changeTheme('default');
    }
  }
}
